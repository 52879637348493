/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');
require('../plugins');

window.Vue = require('vue');

import Lightbox from 'vue-pure-lightbox'
Vue.use(Lightbox)

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


import Conversation from './components/conversation.vue'
Vue.component('conversation', Conversation);

import TenantFileUpload from './components/TenantFileUpload'
Vue.component('tenant-file-upload',TenantFileUpload);

import ChatInterface from './components/ChatBotInterface.vue';
Vue.component('chat-interface', ChatInterface);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

$('#conversationModalCenter').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var estate = button.data('estate') // Extract info from data-* attributes
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this)
    modal.find('.modal-body input#estate').val(estate)
})

$(function () {
    TriggerAlertClose();
});

function TriggerAlertClose() {
    window.setTimeout(function () {
        $(".alert:not(.logged-in-as):not(.change-email):not(.announce)").fadeTo(0, 500).slideUp(500, function () {
            $(this).remove();
        });
    }, 2000);
}
