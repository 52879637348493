var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm($event)
          }
        }
      },
      [
        _c("div", { staticClass: "card m-0" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "vue-dropzone",
                    {
                      ref: "myVueDropzone",
                      staticClass: "w-100 h-100 form-control",
                      attrs: {
                        name: "dropzone",
                        id: "dropzone",
                        options: _vm.dropzoneOptions,
                        useCustomSlot: true
                      }
                    },
                    [
                      _c("div", { staticClass: "dropzone-custom-content" }, [
                        _c("h3", { staticClass: "dropzone-custom-title" }, [
                          _vm._v("Drag and drop")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "subtitle" }, [
                          _vm._v("Dateien hier ablegen oder auswählen")
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _vm.cancelroute
                ? _c("div", { staticClass: "col" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn-lg",
                        attrs: { href: _vm.cancelroute }
                      },
                      [_vm._v("Abbrechen")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(0)
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-primary btn-lg",
          attrs: { type: "submit" }
        },
        [_vm._v("Absenden")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }