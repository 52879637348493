<template>
    <div id="app-chat-interface">
        <div v-if="chatStarted">
            <basic-vue-chat title="SUPPORT"
                            @newOwnMessage="onNewOwnMessage"
                            :new-message="message"
                            :request-upload="imageRequested"
            >
            </basic-vue-chat>
        </div>
        <div v-else class="start-chat">
        </div>
    </div>
</template>

<script>

    import BasicVueChat from 'basic-vue-chat'

    export default {
        name: 'App',
        data() {
            return {
                id: 0,
                message: {},
                user: '',
                chatStarted: false,
               // chatServerUrl: 'http://127.0.0.1:5000/chat/',
               chatServerUrl: location.origin+'/chat-server',
                imageRequested: false
            }
        },
        components: {
            BasicVueChat
        },
        props: {
          userName: {
              type: String,
              required: false,
              default:''
          }
        },
        methods: {
            initChat() {
                this.chatStarted = true
                this.$nextTick(() => {
                    this.addChatbotResponse('Willkommen! Wie kann ich Ihnen behilflich sein?')
                })
            },
            addChatbotResponse(msg) {
                this.id++
                this.message = {
                    id: this.id,
                    author: 'PAUL',
                    contents: msg,
                    date: new Date().toLocaleTimeString()
                }
            },
            onNewOwnMessage(msg, image) {
                const _this = this
                console.log(msg);
                fetch(this.chatServerUrl + `/ask?user=${this.user}&request=${msg}`).then(res => res.json())
                    .then(data => {
                        console.log('answer from sever', data.response)
                        if (data.response) {
                            console.log('data response', data.response)
                            if (data.response.includes('Bitte laden Sie') && data.response.includes('Bild')) {
                                //TODO: this quite hacky - we should expand the structure of the intents to clearly mark when an upload is expected
                                // unfortunately there is no time for it at the monment
                                console.log('found the string')
                                _this.imageRequested = true
                            }
                            _this.addChatbotResponse(data.response)
                        }
                        if (data.slot_options) {
                            _this.$nextTick(()=>this.addChatbotResponse(data.slot_options.join(', ')))
                        }
                        if (data.request_image) {
                            this.imageRequested = true
                        }


                        //Upload image after message was send
                        if (!image) {
                            return
                        }
                        console.log('got image', image)

                        let formData = new FormData()

                        formData.append('photo', image)
                        let csrfToken = document.querySelector('meta[name="csrf-token"]').content
                        fetch(this.chatServerUrl+'/add-attachment', {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'X-CSRF-TOKEN': csrfToken
                            },
                        }).then(res=>{
                            console.log('response from chatbot file upload',res)
                            let message
                           switch(res.status) {
                               case 200:
                                   message = 'Die Datei wurde erfolgreich hochgeladen'
                                   break
                               case 422:
                                   message = 'Die Datei konnte nicht hochgeladen werden. Nur Bilddateien (Jpeg, png, bmp) sind erlaubt.'
                                   break
                               case 433:
                                   message = 'Bitte schildern Sie zuerst Ihr Anliegen, bevor Sie Dateien schicken.'
                                   break
                               default:
                                   message = 'Ein Fehler ist aufgetreten. Die Datei konnte nicht hochgeladen werden'
                                   break
                            }
                            _this.$nextTick(()=>this.addChatbotResponse(message))
                            this.imageRequested = false
                        }).catch(error => {
                            _this.$nextTick(()=>this.addChatbotResponse('Ein Fehler ist aufgetreten. Die Datei konnte nicht hochgeladen werden'))
                        })

                    })
            },
        },
        mounted() {
            this.user = this.userName
            console.log('received logged in user: ' + this.userName)
            this.initChat()
        }
    }
</script>

<style lang="scss">
    #app-chat-interface {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        position: relative;
        width: 75%;
        margin: auto;
    }

    .basic-vue-chat {
        > * {
            width: 100% !important;
            background-color: unset;
            border-radius: 0 !important;
        }
        div.message__contents {
            margin: 0.5em 1em 0.5em 1em;
            border-radius: 0 !important;
            background-color: #F2F2F2 !important;
            text-align: left;
            font: normal normal normal 16px/34px Open Sans;
            letter-spacing: 0px;
            color: #222222;
            padding: 9px 20px 9px 20px !important;
            opacity: 1;
        }

        div.message--own >
         div.message__contents {
             color: #ffffff !important;
             background-color: #222222 !important;
            max-width: 75% !important;
         }


        div.message__author {
            font: normal normal bold 20px/26px Trajan Pro;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
        }

        div.message__date {
            text-align: left;
            font: normal normal normal 16px/34px Open Sans;
            letter-spacing: 0px;
            color: #888888;
            opacity: 1;
            background-color: unset;
            padding: 1em;
        }
        div.input__field {
            border-radius: 0 !important;
            text-align: left;
            input {
                font: normal normal normal 16px/34px Open Sans !important;
            }
            letter-spacing: 0px;
            color: #222222;
            background-color: #F2F2F2;
            opacity: 1;
        }
        border-radius: 0 !important;
        section.window {
            border-radius: 0;
            max-width: 100% !important;
            border: 1px solid #222222 !important;
        }


        #window__messages__container {
            border-radius: 0;
            padding: 21px !important;
        }
        .start-chat div {
            margin: 0.5em;
        }
        > section > header {
            display: none;
        }

        div.window__input__container {
            border: 1px solid #222222 !important;
            height: unset !important;
            padding: 15px;
        }

        div.input__button {
            background-color: #ffffff;
            border-radius: 0px;
            border: 1px solid #222222 !important;
            text-align: center;
            font: normal normal normal 20px/27px Open Sans;
            letter-spacing: -0.4px;
            color: #222222;
            opacity: 1;
            content: "Senden";
            height: 50px !important;
            width: 200px !important;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color:  #222222 !important;
                color:  #ffffff;
            }
        }
        div.input-images-upload {
            font-size:28px;
        }
    }
</style>
