<template>
  <div class="conversation bg-white p-3">
    <h3>{{conv.title}}</h3>
    <div class="conversation-participants">
      Teilnehmer: {{allUserString}}
    </div>
    <div class="v-timeline theme--light" id="conversation-timeline" v-if="messages.length">
      <div class="timeline">
        <div class="v-timeline-item theme--light" v-for="(message, index) in messages" :key="message.id" :class="{'v-timeline-item--right': message.isMe}">
          <div class="v-timeline-item__dot v-timeline-item__dot--large animated fadeIn">
            <div class="v-timeline-item__inner-dot primary">
              <div class="v-avatar" style="height: 48px; width: 48px;" v-if="message.user">
                <img :src="message.user.avatar">
              </div>
            </div>
          </div>
          <div class="v-timeline-item__body">
            <div class="elevation-2 v-card v-sheet theme--light">
              <div class="v-card__text">
                <h5>{{message.title}}</h5>
                {{message.message}}
              </div>
              <div class="attachments text-right pr-2 pb-2">
                <div v-if="message.attachments.length">
                  <lightbox
                    style=""
                    thumbnail="/images/icons/paperclip.png"
                    :images="message.attachments"
                  >
                    <lightbox-default-loader slot="loader"/>
                  </lightbox>
                </div>
                <div v-if="message.pdf.length">
                  <div class="message-pdf" v-for="(pdf,index) in message.pdf">
                    <a :href="pdf.url" target="blank">{{pdf.name}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-timeline-item__opposite">
            <span>{{message.user.full_name}}, {{message.LastUpdatedDateHumanReadable}}</span>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <section class="form w-100">
      <form @submit.prevent="submitForm">
        <div class="card m-0">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-12">
                <h4>Nachricht</h4>
              </div>
              <div class="col-12">
                <label for="title" class="col-md-2 form-control-label">Titel</label>
                <input required="required" name="title"
                  v-model="newMessage.title"
                  v-validate="'required|min:3'"
                  class="form-control" type="text" placeholder="Bitte ausfüllen">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="message" class="col-md-2 form-control-label">Nachricht</label>
                <textarea required="required"name="message"
                  v-model="newMessage.message"
                  v-validate="'required|min:3'"
                  class="form-control" type="text" placeholder="Bitte ausfüllen"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="dropzone" class="col-md-2 form-control-label">Anhang</label>
                <vue-dropzone name="dropzone" class="w-100 h-100 form-control" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true">
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Drag and drop</h3>
                    <div class="subtitle">Dateien hier ablegen oder auswählen</div>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div v-if="cancelroute" class="col">
                <a :href="cancelroute" class="btn btn-primary btn-lg">Abbrechen</a>
              </div>
              <div class="col text-right">
                <button class="btn btn-outline-primary btn-lg" type="submit">Absenden</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

// Dropzone.autoDiscover = false;
import axios from 'axios'

export default {
      name: "Conversation",
      props: [
        'conversation',
        'route',
        'cancelroute'
      ],
      components: {
        vueDropzone: vue2Dropzone
      },
      data() {
        return {
            conv: this.conversation,
            messages: this.conversation.messages,
            lightboxImages: [],
            newMessage : {
              title: '',
              message: '',
              attachments: [],
              route: this.route
            },
            dropzoneOptions: {
                autoDiscover: false,
                url: this.route,
                thumbnailWidth: 150,
                autoProcessQueue : false,
                addRemoveLinks : true,
                dictRemoveFile: "Löschen",
                headers: { "My-Awesome-Header": "header value" },
                maxFileSize : 5,
                acceptedFiles: '.pdf,.doc,docx,.jpg,.jpeg,.png,.gif'
            }
        }
      },
      mounted() {

      },
      computed: {
        allUserString() {
          return Array.prototype.map.call(this.conversation.users, s => s.user ? s.user.full_name : s.full_name ).join(", ").toString();
        }
      },
      methods: {
        submitForm() {
          this.$validator.validateAll().then((result) => {
            if (result) {
              // eslint-disable-next-line
              let formData = new FormData();
              formData.append('title', this.newMessage.title);
              formData.append('message', this.newMessage.message);
              formData.append('conversation', this.conv.id);
              let files = this.$refs.myVueDropzone.getAcceptedFiles();
              for( var i = 0; i < files.length; i++ ){
                let file = files[i];

                formData.append('files[' + i + ']', file);
              }

              axios.post( this.newMessage.route,
                formData,
                {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
                }
              ).then(function(response) {

                this.newMessage.title = '';
                this.newMessage.message = '';
                this.$refs.myVueDropzone.removeAllFiles()
                this.messages.push(new Object(response.data.message));
                this.newMessage.btnDisable = false;

                swal({
                  title: "Nachricht versendet!",
                  showCancelButton: false,
                  confirmButtonText: "OK",
                  type: 'success'
                }).then((value) => {
                  if(response.data.reload) {
                    window.location.href = response.data.reload;
                  }
                });
              }.bind(this))
              .catch(function(e) {
                swal({
                  title: "Nachricht nicht versendet!",
                  text: "Leider ist ein Fehler aufgetreten, bitte versuchen Sie es erneut!",
                  showCancelButton: false,
                  confirmButtonText: "OK",
                  type: 'error'
                });
              });
              return;
            }

            alert('Correct them errors!');
          });
        },
      }
  }
</script>
