var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app-chat-interface" } }, [
    _vm.chatStarted
      ? _c(
          "div",
          [
            _c("basic-vue-chat", {
              attrs: {
                title: "SUPPORT",
                "new-message": _vm.message,
                "request-upload": _vm.imageRequested
              },
              on: { newOwnMessage: _vm.onNewOwnMessage }
            })
          ],
          1
        )
      : _c("div", { staticClass: "start-chat" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }