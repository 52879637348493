<template>
    <div>
        <form @submit.prevent="submitForm">
            <div class="card m-0">
                <div class="card-body">
    <div class="form-group row">
        <div class="col-12">
            <vue-dropzone name="dropzone" class="w-100 h-100 form-control" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true">
                <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Drag and drop</h3>
                    <div class="subtitle">Dateien hier ablegen oder auswählen</div>
                </div>
            </vue-dropzone>
        </div>
    </div>
                </div>
            <div class="card-footer">
                <div class="row">
                    <div v-if="cancelroute" class="col">
                        <a :href="cancelroute" class="btn btn-primary btn-lg">Abbrechen</a>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-outline-primary btn-lg" type="submit">Absenden</button>
                    </div>
                </div>
            </div>
            </div>
        </form>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    // Dropzone.autoDiscover = false;
    import axios from 'axios'

    export default {
        name: "TenantFileUpload",
        props: [
            'route',
            'cancelroute'
        ],
        components: {
            vueDropzone: vue2Dropzone
        },
        data() {
            return {
                lightboxImages: [],
                dropzoneOptions: {
                    autoDiscover: false,
                    url: this.route,
                    thumbnailWidth: 150,
                    autoProcessQueue : false,
                    addRemoveLinks : true,
                    dictRemoveFile: "Löschen",
                    headers: { "My-Awesome-Header": "header value" },
                    maxFileSize : 5,
                    acceptedFiles: '.pdf,.doc,docx,.jpg,.jpeg,.png,.gif',
                }
            }
        },
        methods: {
            submitForm() {
                let formData = new FormData();
                let files = this.$refs.myVueDropzone.getAcceptedFiles();
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];

                    formData.append('files[' + i + ']', file);
                }

                axios.post(this.route,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function (response) {

                    this.$refs.myVueDropzone.removeAllFiles()
                    swal({
                        title: "Datei hochgeladen!",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        type: 'success'
                    }).then((value) => {
                        if (true) {
                            window.location.href = "/dashboard?uploaded="+(+new Date());
                        }
                    });
                }.bind(this))
                    .catch(function (e) {
                        swal({
                            title: "Datei konnte nicht hochgeladen werden",
                            text: e,
                            showCancelButton: false,
                            confirmButtonText: "OK",
                            type: 'error'
                        });
                    });
                return;

            },
        }
    }

</script>

