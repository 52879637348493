var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conversation bg-white p-3" }, [
    _c("h3", [_vm._v(_vm._s(_vm.conv.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "conversation-participants" }, [
      _vm._v("\n    Teilnehmer: " + _vm._s(_vm.allUserString) + "\n  ")
    ]),
    _vm._v(" "),
    _vm.messages.length
      ? _c(
          "div",
          {
            staticClass: "v-timeline theme--light",
            attrs: { id: "conversation-timeline" }
          },
          [
            _c(
              "div",
              { staticClass: "timeline" },
              _vm._l(_vm.messages, function(message, index) {
                return _c(
                  "div",
                  {
                    key: message.id,
                    staticClass: "v-timeline-item theme--light",
                    class: { "v-timeline-item--right": message.isMe }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "v-timeline-item__dot v-timeline-item__dot--large animated fadeIn"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "v-timeline-item__inner-dot primary" },
                          [
                            message.user
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "v-avatar",
                                    staticStyle: {
                                      height: "48px",
                                      width: "48px"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: message.user.avatar }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "v-timeline-item__body" }, [
                      _c(
                        "div",
                        {
                          staticClass: "elevation-2 v-card v-sheet theme--light"
                        },
                        [
                          _c("div", { staticClass: "v-card__text" }, [
                            _c("h5", [_vm._v(_vm._s(message.title))]),
                            _vm._v(
                              "\n              " +
                                _vm._s(message.message) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "attachments text-right pr-2 pb-2" },
                            [
                              message.attachments.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "lightbox",
                                        {
                                          attrs: {
                                            thumbnail:
                                              "/images/icons/paperclip.png",
                                            images: message.attachments
                                          }
                                        },
                                        [
                                          _c("lightbox-default-loader", {
                                            attrs: { slot: "loader" },
                                            slot: "loader"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              message.pdf.length
                                ? _c(
                                    "div",
                                    _vm._l(message.pdf, function(pdf, index) {
                                      return _c(
                                        "div",
                                        { staticClass: "message-pdf" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: pdf.url,
                                                target: "blank"
                                              }
                                            },
                                            [_vm._v(_vm._s(pdf.name))]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "v-timeline-item__opposite" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(message.user.full_name) +
                            ", " +
                            _vm._s(message.LastUpdatedDateHumanReadable)
                        )
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("section", { staticClass: "form w-100" }, [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("div", { staticClass: "card m-0" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-group row" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 form-control-label",
                      attrs: { for: "title" }
                    },
                    [_vm._v("Titel")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newMessage.title,
                        expression: "newMessage.title"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:3",
                        expression: "'required|min:3'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "required",
                      name: "title",
                      type: "text",
                      placeholder: "Bitte ausfüllen"
                    },
                    domProps: { value: _vm.newMessage.title },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.newMessage, "title", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 form-control-label",
                      attrs: { for: "message" }
                    },
                    [_vm._v("Nachricht")]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newMessage.message,
                        expression: "newMessage.message"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:3",
                        expression: "'required|min:3'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "required",
                      name: "message",
                      type: "text",
                      placeholder: "Bitte ausfüllen"
                    },
                    domProps: { value: _vm.newMessage.message },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.newMessage, "message", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-md-2 form-control-label",
                        attrs: { for: "dropzone" }
                      },
                      [_vm._v("Anhang")]
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-dropzone",
                      {
                        ref: "myVueDropzone",
                        staticClass: "w-100 h-100 form-control",
                        attrs: {
                          name: "dropzone",
                          id: "dropzone",
                          options: _vm.dropzoneOptions,
                          useCustomSlot: true
                        }
                      },
                      [
                        _c("div", { staticClass: "dropzone-custom-content" }, [
                          _c("h3", { staticClass: "dropzone-custom-title" }, [
                            _vm._v("Drag and drop")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v("Dateien hier ablegen oder auswählen")
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer" }, [
              _c("div", { staticClass: "row" }, [
                _vm.cancelroute
                  ? _c("div", { staticClass: "col" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-lg",
                          attrs: { href: _vm.cancelroute }
                        },
                        [_vm._v("Abbrechen")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(1)
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h4", [_vm._v("Nachricht")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-primary btn-lg",
          attrs: { type: "submit" }
        },
        [_vm._v("Absenden")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }